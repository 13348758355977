import content from '../../data/content';
import Views from '../Views/Views';
import './ReaqtaInteractiveTour.scss';

const ReaqtaInteractiveTour = () => {
  return (
    <div className="reaqta-interactive-tour widget-styles">
      <div className="widget-inner widget-reset">
        <Views content={content} />
      </div>
    </div>
  );
};

export default ReaqtaInteractiveTour;
