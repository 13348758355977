import Template from '../Template/Template';
import Navigation from '../Navigation/Navigation';
import UIFrame from '../UIFrame/UIFrame';
import Iframe from '../Iframe/Iframe';
import Lightbox from '../Lightbox/Lightbox';
import {
  Content,
  Scenario as ScenarioType,
  SetStepOrString,
  Step,
} from '../../lib/types';
import './Scenario.scss';
import PublicImage from 'src/common/components/PublicImage';
import { messages } from '../../locales/messages';
import { useIntl, MessageDescriptor } from 'react-intl';

type ScenarioProps = {
  currentScenario: ScenarioType;
  currentStep: Step;
  setStep: SetStepOrString;
  triggerRestart: () => void;
  steps: Step[];
  advance: () => void;
  rewind: () => void;
  currentStepIsLastStep: boolean;
  hasOneScenario: boolean;
  content: Content;
};

const Scenario = ({
  currentScenario,
  currentStep,
  setStep,
  triggerRestart,
  steps,
  advance,
  rewind,
  currentStepIsLastStep,
  hasOneScenario,
  content: { end },
}: ScenarioProps) => {
  const intl = useIntl();
  const intlBase = `${currentScenario.key}.${currentStep.key}`;
  const altText = intl.formatMessage(
    (messages as Record<string, MessageDescriptor>)[`${intlBase}.alt`],
  );
  const doFocus = () => {
    if (currentStep.key !== 'stepA1') {
      (document.getElementsByClassName('screen')[0] as HTMLElement)?.focus();
    }
  };

  const { video, imagePath, hotspots } = currentStep;

  return (
    <Template
      navigation={
        <Navigation
          end={end}
          currentStep={currentStep}
          currentStepIsLastStep={currentStepIsLastStep}
          steps={steps}
          setStep={setStep}
          currentScenario={currentScenario}
          triggerRestart={triggerRestart}
          advance={advance}
          rewind={rewind}
          hasOneScenario={hasOneScenario}
        />
      }
    >
      {currentStep && (
        <UIFrame>
          {video && <Iframe src={video} />}
          {imagePath && (
            <PublicImage
              key={currentStep.key}
              imgPath={imagePath}
              widgetId="reaqta-interactive-tour"
              className="screen"
              alt={altText}
              tabIndex={0}
              onLoad={doFocus}
            />
          )}
          {hotspots && <Lightbox setStep={setStep} hotspots={hotspots} />}
        </UIFrame>
      )}
    </Template>
  );
};

export default Scenario;
