import { ChevronLeft32, ChevronRight32, Reset32 } from '@carbon/icons-react';
import { current } from '@reduxjs/toolkit';
import { Button } from 'carbon-components-react';
import { NavigationProps } from '../Navigation/Navigation';
import './Pager.scss';

type PagerProps = {
  advance: NavigationProps['advance'];
  rewind: NavigationProps['rewind'];
  currentStep: NavigationProps['currentStep'];
};

const Pager = ({ advance, rewind, currentStep }: PagerProps) => {
  return (
    <div className="interactive-widget__pager-nav">
      <Button
        disabled={currentStep.key === 'stepA1' ? true : false}
        className="interactive-widget__pager-button"
        renderIcon={ChevronLeft32}
        iconDescription="Previous"
        hasIconOnly
        onClick={rewind}
      />

      <Button
        className="interactive-widget__pager-button"
        renderIcon={currentStep.key === 'stepA19' ? Reset32 : ChevronRight32}
        iconDescription={currentStep.key === 'stepA19' ? 'Reset' : 'Next'}
        hasIconOnly
        onClick={advance}
      />
    </div>
  );
};

export default Pager;
