import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'widget.title': {
    defaultMessage: 'Start your interactive tour now',
    id: 'cOtsTA',
  },
  'widget.subtitle': {
    defaultMessage:
      'Click the white prompts to discover how IBM Security® QRadar® EDR identifies and remediates a threat.',
    id: 'lLxdQF',
  },
  'template.nextSteps': { defaultMessage: 'Next Steps', id: 'xe05Cm' },
  'template.menu': { defaultMessage: 'Menu', id: 'tKMlOc' },
  'template.complete': { defaultMessage: 'Complete', id: 'U78NhE' },
  'scenarioA.title': { defaultMessage: 'App Integration', id: 'ce/bnO' },
  'scenarioA.stepA1.title': {
    defaultMessage: 'Welcome to the IBM Security® QRadar® EDR Demo',
    id: '9zhAk9',
  },
  'scenarioA.stepA1.body': {
    defaultMessage:
      '<p>These days, hackers are getting more and more sophisticated, requiring security teams to take immediate and effective actions.</p><p>This walkthrough will show you how you can remediate threats quickly with QRadar EDR.</p>',
    id: 'UrPT3l',
  },
  'scenarioA.stepA1.alt': {
    defaultMessage:
      'QRadar EDR home page shows threat severity levels with 37 High, 18 Medium and 2 Low on top and All Threats Cleared at bottom',
    id: 'xP+NCD',
  },
  'scenarioA.stepA1.nextSteps': {
    defaultMessage: "Let's get started",
    id: 'Ihg+6O',
  },
  'scenarioA.stepA2.title': {
    defaultMessage: 'Start with a high-level view from the home screen',
    id: 'ZLaLPu',
  },
  'scenarioA.stepA2.body': {
    defaultMessage:
      '<p>Your home screen will always provide the latest high-level view of alerts and cleared threats to quickly show the state of your organization’s endpoint ecosystem.</p><p>Click the white dot to see what happens when an alert is triggered.</p>',
    id: 'xxfGyl',
  },
  'scenarioA.stepA2.alt': {
    defaultMessage:
      'QRadar EDR home page with threat levels (37 High, 18 Medium, 2 Low) on top, All Threats Cleared at bottom and blue dot center',
    id: '7F5v9c',
  },
  'scenarioA.stepA3.title': {
    defaultMessage: 'View a specific threat alert ',
    id: 'pjC0il',
  },
  'scenarioA.stepA3.body': {
    defaultMessage:
      '<p>A threat alert is automatically generated as soon as QRadar EDR detects a behavioral anomaly.</p><p>In this example, an employee opened a Microsoft Word document, but QRadar EDR detected that “winword.exe” also launched a process called “tryme.exe,” which isn’t a typical behavior of Microsoft Word.</p><p>On the top left, you’ll see the severity of the threat which is at medium. On the right of the dashboard, all information about the alert like processes, endpoints involved, and how the threat maps to the MITRE ATT&CK framework is shown.</p>',
    id: 'VaVt2e',
  },
  'scenarioA.stepA3.alt': {
    defaultMessage:
      '“Behavioral anomaly detected” shown in blue bar under Tasks; medium-level threat shown upper left; alert info shown right',
    id: 'NOlII8',
  },
  'scenarioA.stepA3.nextSteps': {
    defaultMessage: 'Click to trigger an alert',
    id: 'GawN+M',
  },
  'scenarioA.stepA4.title': {
    defaultMessage: 'Investigate the alert',
    id: 'wylVYj',
  },
  'scenarioA.stepA4.body': {
    defaultMessage:
      'From this alert detail page, you have a simple yet thorough overview that helps you analyze the alert and make a confident assessment about whether it’s malicious or benign.',
    id: 'HIJOvA',
  },
  'scenarioA.stepA4.alt': {
    defaultMessage:
      'Summary of Alert Trigger on top left, Key Events on middle left, Affected Endpoint on bottom left and Status on top right',
    id: '7CGaMs',
  },
  'scenarioA.stepA5.title': {
    defaultMessage: 'Review the behavioral tree',
    id: 'rn9G5q',
  },
  'scenarioA.stepA5.body': {
    defaultMessage:
      '<p>For each alert, a behavioral tree is created that provides a chronological, visual storyboard of the applications and behaviors that triggered the alert.</p><p>Instead of manually going through endless event logs, the behavioral tree helps you see the breadth of the activity on a single screen and make effective decisions quickly.</p>',
    id: 'I1cQ4j',
  },
  'scenarioA.stepA5.alt': {
    defaultMessage:
      'Affected Endpoint top left; Behavioral Tree entire bottom (a visual storyboard showing the breadth of threat activity)',
    id: '3gIetA',
  },
  'scenarioA.stepA6.title': {
    defaultMessage: 'Click for details about applications and behaviors',
    id: 'OjGFJS',
  },
  'scenarioA.stepA6.body': {
    defaultMessage:
      '<p>Clicking on the circles in the behavioral tree reveals information about the applications that were launched.</p><p>Here you can see that through the “winword.exe” application, a Microsoft Word document was opened on a desktop.</p><p>This is a signed application, and nothing seems suspicious yet. It’s important to note that some attacks are launched through signed applications and can bypass regular antivirus software. Let’s see what QRadar EDR detected.</p>',
    id: 'lBtSRH',
  },
  'scenarioA.stepA6.alt': {
    defaultMessage:
      'Highlighted Behavioral Tree shows Microsoft Word document was opened on a desktop through winword.exe application',
    id: '7eT/bY',
  },
  'scenarioA.stepA7.title': {
    defaultMessage: 'Word launches a second application',
    id: 'CcGZx9',
  },
  'scenarioA.stepA7.body': {
    defaultMessage:
      '<p>You can see that “winword.exe” launched another application called “tryme.exe.”</p><p>When you click on the “tryme.exe" application, you see that it’s running from a temp directory, and it’s not signed. You might not think much about it, other than it’s a little suspicious. Keep investigating to view associated behaviors.</p>',
    id: '8M3KbZ',
  },
  'scenarioA.stepA7.alt': {
    defaultMessage:
      'Highlighted Behavioral Tree shows winword.exe launched another app, tryme.exe, that’s running from a temp directory',
    id: 'n/dLSl',
  },
  'scenarioA.stepA8.title': {
    defaultMessage: 'A suspicious behavior is detected',
    id: 'SfLzWS',
  },
  'scenarioA.stepA8.body': {
    defaultMessage:
      '<p>To further understand what’s happening, you can click on the hexagons in the behavioral tree. They represent the behaviors and are color-coded by severity.</p><p>Notice that the “tryme.exe” application is behaving like a cross-process operation. In other words, it’s conducting a code injection into Internet Explorer.</p><p>This is highly suspicious behavior, and you’ll want to keep investigating.</p>',
    id: 'OTecUL',
  },
  'scenarioA.stepA8.alt': {
    defaultMessage:
      'Behavioral Tree color-coded hexagons indicate that the tryme.exe application is behaving like a Cross process Operation',
    id: 'UYDoaT',
  },
  'scenarioA.stepA9.title': {
    defaultMessage: 'A suspicious behavior is detected',
    id: 'SfLzWS',
  },
  'scenarioA.stepA9.body': {
    defaultMessage:
      '<p>Next, click on the browser application. You’ve got another legitimate application signed by Microsoft.</p><p>When you get to the end of this visual storyboard, you see that “tryme.exe” is running from a suspicious directory, injecting code into Internet Explorer that’s causing it to behave like a keylogger.</p><p>Do you still want this application to run?</p>',
    id: '2EDnmq',
  },
  'scenarioA.stepA9.alt': {
    defaultMessage:
      'Tryme.exe is injecting code into the Internet Explorer app (iexplore.exe) that’s causing it to behave like a keylogger',
    id: '41F+Nj',
  },
  'scenarioA.stepA10.title': {
    defaultMessage: 'Access containment controls',
    id: 'jRVRb/',
  },
  'scenarioA.stepA10.body': {
    defaultMessage:
      '<p>Once you’ve analyzed a threat and deemed it malicious, you can take several steps. Without leaving the behavioral tree, you can access containment controls and three stages of incident response.</p><p>Triage: See the attack, with AI assistance to highlight high-impact apps and behaviors.</p><p>Respond: Kill malicious processes and stop the threat immediately, mitigating further risk and impact.</p><p>Protect: Create a blocklist policy to prevent the threat from running on other endpoints.</p>',
    id: 'r6IVAZ',
  },
  'scenarioA.stepA10.alt': {
    defaultMessage:
      'Behavioral Tree enables access to containment controls and three incident response stages—Triage, Respond and Protect',
    id: 'XZXSoZ',
  },
  'scenarioA.stepA11.title': {
    defaultMessage: 'View all infected endpoints at once',
    id: 'dWgvrS',
  },
  'scenarioA.stepA11.body': {
    defaultMessage:
      '<p>Next, the Infrastructure Artifacts section shows how many other endpoints were affected by this malicious activity. You’ll know if this was an isolated threat or if it’s recurring and on which endpoints</p><p>You can then terminate malicious processes or completely isolate the infected endpoints from the network.</p>',
    id: 'he18e6',
  },
  'scenarioA.stepA11.alt': {
    defaultMessage:
      'Behavior Anomalies, top left; Infrastructure Artifacts, center displaying affected endpoints; Cloud Analysis Score, bottom',
    id: 'FQjodu',
  },
  'scenarioA.stepA12.title': {
    defaultMessage: 'Create a remediation plan',
    id: '6EjlSY',
  },
  'scenarioA.stepA12.body': {
    defaultMessage:
      '<p>You may also want to create workflows to take action against specific threats. These plans can then be triggered autonomously when a similar threat is detected in the future.</p><p>From this window, under the “Terminate Process” tab, you can choose the applications and processes you’d like to terminate and remove.</p>',
    id: '9aq/k3',
  },
  'scenarioA.stepA12.alt': {
    defaultMessage:
      'Create Remediation pop-up displays Terminate Process tab to enable the creation of workflows',
    id: '1oPGxI',
  },
  'scenarioA.stepA13.title': {
    defaultMessage: 'Create a remediation plan',
    id: '6EjlSY',
  },
  'scenarioA.stepA13.body': {
    defaultMessage:
      '<p>In this case, it will be the “tryme.exe,” "iexplore.exe" and "winword.exe."</p><p>The behavioral tree empowers you to understand the threat better, respond faster and protect your organization in the most effective way.</p>',
    id: 'VAIidN',
  },
  'scenarioA.stepA13.alt': {
    defaultMessage:
      'Create Remediation pop-up displaying Terminate Process shows tryme.exe, iexplore.exe and winword.exe being terminated',
    id: 'nVrpWr',
  },
  'scenarioA.stepA14.title': {
    defaultMessage: 'Create a remediation plan',
    id: '6EjlSY',
  },
  'scenarioA.stepA14.body': {
    defaultMessage:
      '<p>You’ll also have the option to select any dropped executables, and filesystem and registry persistence—and remove them. You can also select the endpoint you’d like to isolate as a part of this remediation plan.</p>',
    id: 'R1Li//',
  },
  'scenarioA.stepA14.alt': {
    defaultMessage:
      'Create Remediation pop-up displays Remediation Summary tab where all removed entries and processes are listed',
    id: 'RQ9/LM',
  },
  'scenarioA.stepA15.title': {
    defaultMessage: 'Create a remediation plan',
    id: '6EjlSY',
  },
  'scenarioA.stepA15.body': {
    defaultMessage:
      '<p>Now that the remediation plan is created, you can close the alert.</p>',
    id: 'dxBaCk',
  },
  'scenarioA.stepA15.alt': {
    defaultMessage:
      'Alerts page displays information on Alert Trigger, Affected Endpoint and Status, and Close Alert action in upper right',
    id: 'SzXbMH',
  },
  'scenarioA.stepA16.title': {
    defaultMessage:
      'Classify a threat as malicious or benign to train the Cyber Assistant',
    id: 'MCR8G3',
  },
  'scenarioA.stepA16.body': {
    defaultMessage:
      '<p>Over time, the Cyber Assistant learns from your decisions, then retains the intellectual capital and learned behaviors to make recommendations and help reduce false positives.</p>',
    id: 'NhlRrI',
  },
  'scenarioA.stepA16.alt': {
    defaultMessage:
      'Close Alert pop-up shows Alert State checkboxes classifying threats as Benign or Malicious to train the Cyber Assistant',
    id: 'zNIZFW',
  },
  'scenarioA.stepA17.title': {
    defaultMessage:
      'Classify a threat as malicious or benign to train the Cyber Assistant',
    id: 'MCR8G3',
  },
  'scenarioA.stepA17.body': {
    defaultMessage:
      '<p>Eventually, the Cyber Assistant will automatically assess and remediate similar alerts with minimal human intervention. This level of learning and automation multiplies your efforts and helps with alert fatigue.</p>',
    id: 'iMu6Y7',
  },
  'scenarioA.stepA17.alt': {
    defaultMessage:
      'Close Alert pop-up shows Malicious box checked with Confirm action highlighted',
    id: 'eobu3w',
  },
  'scenarioA.stepA18.title': {
    defaultMessage: 'Cyber Assistant recommendations',
    id: 'ZbhNiu',
  },
  'scenarioA.stepA18.body': {
    defaultMessage:
      '<p>The Cyber Assistant collects data and uses AI to constantly learn from threat patterns and remediation plans to assess similar threats.</p><p>Then it autonomously makes assessments about new alerts that have a similar telemetry. This similarity is shown on the right side. Anything with a similarity of 85% and above will be used for this assessment, helping you to make informed decisions about new alerts.</p>',
    id: 'yHdcy7',
  },
  'scenarioA.stepA18.alt': {
    defaultMessage:
      'Cyber Assistant displays Malicious Alerts with Impact Score in top right and Similar Alerts below it',
    id: 'Rsg5se',
  },
  'scenarioA.stepA19.title': {
    defaultMessage: 'Want to see more?',
    id: '2pmZnu',
  },
  'scenarioA.stepA19.body': {
    defaultMessage:
      '<p>If you’d like to learn more about QRadar EDR’s features, pricing or integrations, please contact us.</p>',
    id: 'zv2qX8',
  },
  'scenarioA.stepA19.alt': {
    defaultMessage:
      'Cyber Assistant page contained in a laptop’s white silhouette on a blue background',
    id: 'SyNG6m',
  },
});

export type messageType = keyof typeof messages;
