import assets from './assets';

export default {
  start: {
    video: assets.startVideo,
  },
  end: {
    ctaUrl: 'https://www.ibm.com/account/reg/signup?formid=urx-51802',
  },
  scenarios: [
    {
      key: 'scenarioA',
      title: 'App Integration',
      steps: [
        {
          key: 'stepA1',
          withNextSteps: true,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.intro,
          hotspots: [{ x: 658, y: 380, goTo: 'stepA2' }],
        },
        {
          key: 'stepA2',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.home,
          hotspots: [{ x: 658, y: 380, goTo: 'stepA3' }],
        },
        {
          key: 'stepA3',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.threat,
          hotspots: [{ x: 1095, y: 785, goTo: 'stepA4' }],
        },
        {
          key: 'stepA4',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.investigate,
          hotspots: [{ x: 170, y: 210, goTo: 'stepA5' }],
        },
        {
          key: 'stepA5',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.behaviourTree,
          hotspots: [{ x: 595, y: 615, goTo: 'stepA6' }],
        },
        {
          key: 'stepA6',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.checkApplication,
          hotspots: [{ x: 665, y: 665, goTo: 'stepA7' }],
        },
        {
          key: 'stepA7',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.launchedSuspicious,
          hotspots: [{ x: 798, y: 620, goTo: 'stepA8' }],
        },
        {
          key: 'stepA8',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.codeInjection,
          hotspots: [{ x: 1018, y: 610, goTo: 'stepA9' }],
        },
        {
          key: 'stepA9',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.suspiciousBehaviour,
          hotspots: [{ x: 1018, y: 610, goTo: 'stepA10' }],
        },
        {
          key: 'stepA10',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.containmentControls,
          hotspots: [{ x: 613, y: 356, goTo: 'stepA11' }],
        },
        {
          key: 'stepA11',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.infectedEndpoints,
          hotspots: [{ x: 820, y: 340, goTo: 'stepA12' }],
        },
        {
          key: 'stepA12',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.remediationPlan,
          hotspots: [{ x: 395, y: 317, goTo: 'stepA13' }],
        },
        {
          key: 'stepA13',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.remediationPlan2,
          hotspots: [{ x: 1023, y: 610, goTo: 'stepA14' }],
        },
        {
          key: 'stepA14',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.remediationPlan3,
          hotspots: [{ x: 1027, y: 600, goTo: 'stepA15' }],
        },
        {
          key: 'stepA15',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.closeAlert,
          hotspots: [{ x: 1250, y: 145, goTo: 'stepA16' }],
        },
        {
          key: 'stepA16',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.classifyThreat,
          hotspots: [{ x: 658, y: 250, goTo: 'stepA17' }],
        },
        {
          key: 'stepA17',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.markMalicious,
          hotspots: [{ x: 855, y: 300, goTo: 'stepA18' }],
        },
        {
          key: 'stepA18',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.cyberAssistant,
          hotspots: [{ x: 1200, y: 420, goTo: 'stepA19' }],
        },
        {
          key: 'stepA19',
          withNextSteps: false,
          withMore: false,
          withBody: true,
          withBullets: [],
          imagePath: assets.end,
        },
      ],
    },
  ],
};
