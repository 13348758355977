const images = {
  intro: 'images/intro.jpg',
  home: 'images/1-home.jpg',
  threat: 'images/2-threatAlert.jpg',
  investigate: 'images/3-investigateAlert.jpg',
  scenarioBstep1: 'images/scenarioB-step1.jpg',
  behaviourTree: 'images/4-reviewBehavioralTree.jpg',
  checkApplication: 'images/5-checkApplication.jpg',
  launchedSuspicious: 'images/6-launchedSuspiciousActivity.jpg',
  codeInjection: 'images/7-codeInjectionActivity.jpg',
  suspiciousBehaviour: 'images/8-suspiciousBehavior.jpg',
  containmentControls: 'images/9-containmentControls.jpg',
  infectedEndpoints: 'images/10-infectedEndpoints.jpg',
  remediationPlan: 'images/11-remediationPlan.jpg',
  remediationPlan2: 'images/12-remediationPlan2.jpg',
  remediationPlan3: 'images/13-remediationPlan3.jpg',
  closeAlert: 'images/14-closeAlert.jpg',
  classifyThreat: 'images/15-classifyThreat.jpg',
  markMalicious: 'images/16-markMalicious.jpg',
  cyberAssistant: 'images/17-cyberAssistant.jpg',
  end: 'images/18-end.jpg',
};

const videos = {
  startVideo:
    'https://cdnapisec.kaltura.com/p/1511271/sp/151127100/embedIframeJs/uiconf_id/44723621/partner_id/1511271?iframeembed=true&playerId=kaltura_player&entry_id=1_03b0lysy&flashvars[akamaiHD.loadingPolicy]=preInitialize&flashvars[akamaiHD.asyncInit]=true&flashvars[twoPhaseManifest]=true&flashvars[streamerType]=hdnetworkmanifest&flashvars[localizationCode]=en&flashvars[leadWithHTML5]=true&flashvars[sideBarContainer.plugin]=true&flashvars[sideBarContainer.position]=left&flashvars[sideBarContainer.clickToClose]=true&flashvars[chapters.plugin]=true&flashvars[chapters.layout]=vertical&flashvars[sideBarContainer.plugin]=false&flashvars[controlBarContainer.plugin]=false&flashvars[topBarContainer.plugin]=false&flashvars[unMuteOverlayButton.plugin]=false&flashvars[autoPlay]=true&flashvars[loop]=true&flashvars[chapters.thumbnailRotator]=false&flashvars[streamSelector.plugin]=true&flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&flashvars[dualScreen.plugin]=true&flashvars[Kaltura.addCrossoriginToIframe]=true&&wid=1_ak7osukk',
  scenarioAstep2:
    'https://cdnapisec.kaltura.com/p/1511271/sp/151127100/embedIframeJs/uiconf_id/44723621/partner_id/1511271?iframeembed=true&playerId=kaltura_player&entry_id=1_9e2kk9ql&flashvars[akamaiHD.loadingPolicy]=preInitialize&flashvars[akamaiHD.asyncInit]=true&flashvars[twoPhaseManifest]=true&flashvars[streamerType]=hdnetworkmanifest&flashvars[localizationCode]=en&flashvars[leadWithHTML5]=true&flashvars[sideBarContainer.plugin]=true&flashvars[sideBarContainer.position]=left&flashvars[sideBarContainer.clickToClose]=true&flashvars[chapters.plugin]=true&flashvars[chapters.layout]=vertical&flashvars[sideBarContainer.plugin]=false&flashvars[controlBarContainer.plugin]=false&flashvars[topBarContainer.plugin]=false&flashvars[unMuteOverlayButton.plugin]=false&flashvars[autoPlay]=true&flashvars[loop]=true&flashvars[chapters.thumbnailRotator]=false&flashvars[streamSelector.plugin]=true&flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&flashvars[dualScreen.plugin]=true&flashvars[Kaltura.addCrossoriginToIframe]=true&&wid=1_7ui6a5u5',
  scenarioAstep3:
    'https://cdnapisec.kaltura.com/p/1511271/sp/151127100/embedIframeJs/uiconf_id/44723621/partner_id/1511271?iframeembed=true&playerId=kaltura_player&entry_id=1_bhcnd8ye&flashvars[akamaiHD.loadingPolicy]=preInitialize&flashvars[akamaiHD.asyncInit]=true&flashvars[twoPhaseManifest]=true&flashvars[streamerType]=hdnetworkmanifest&flashvars[localizationCode]=en&flashvars[leadWithHTML5]=true&flashvars[sideBarContainer.plugin]=true&flashvars[sideBarContainer.position]=left&flashvars[sideBarContainer.clickToClose]=true&flashvars[chapters.plugin]=true&flashvars[chapters.layout]=vertical&flashvars[chapters.thumbnailRotator]=false&flashvars[sideBarContainer.plugin]=false&flashvars[controlBarContainer.plugin]=false&flashvars[topBarContainer.plugin]=false&flashvars[unMuteOverlayButton.plugin]=false&flashvars[autoPlay]=true&flashvars[loop]=true&flashvars[streamSelector.plugin]=true&flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&flashvars[dualScreen.plugin]=true&flashvars[Kaltura.addCrossoriginToIframe]=true&&wid=1_bpc4a4lj',
  scenarioAstep4:
    'https://cdnapisec.kaltura.com/p/1511271/sp/151127100/embedIframeJs/uiconf_id/44723621/partner_id/1511271?iframeembed=true&playerId=kaltura_player&entry_id=1_sztxzvi3&flashvars[akamaiHD.loadingPolicy]=preInitialize&flashvars[akamaiHD.asyncInit]=true&flashvars[twoPhaseManifest]=true&flashvars[streamerType]=hdnetworkmanifest&flashvars[localizationCode]=en&flashvars[leadWithHTML5]=true&flashvars[sideBarContainer.plugin]=true&flashvars[sideBarContainer.position]=left&flashvars[sideBarContainer.clickToClose]=true&flashvars[chapters.plugin]=true&flashvars[chapters.layout]=vertical&flashvars[chapters.thumbnailRotator]=false&flashvars[sideBarContainer.plugin]=false&flashvars[controlBarContainer.plugin]=false&flashvars[topBarContainer.plugin]=false&flashvars[unMuteOverlayButton.plugin]=false&flashvars[autoPlay]=true&flashvars[loop]=true&flashvars[streamSelector.plugin]=true&flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&flashvars[dualScreen.plugin]=true&flashvars[Kaltura.addCrossoriginToIframe]=true&&wid=1_vyjqcy5y',
  scenarioBstep2:
    'https://cdnapisec.kaltura.com/p/1511271/sp/151127100/embedIframeJs/uiconf_id/44723621/partner_id/1511271?iframeembed=true&playerId=kaltura_player&entry_id=1_bgz4epqg&flashvars[akamaiHD.loadingPolicy]=preInitialize&flashvars[akamaiHD.asyncInit]=true&flashvars[twoPhaseManifest]=true&flashvars[streamerType]=hdnetworkmanifest&flashvars[localizationCode]=en&flashvars[leadWithHTML5]=true&flashvars[sideBarContainer.plugin]=true&flashvars[sideBarContainer.position]=left&flashvars[sideBarContainer.clickToClose]=true&flashvars[chapters.plugin]=true&flashvars[chapters.layout]=vertical          &flashvars[sideBarContainer.plugin]=false&flashvars[controlBarContainer.plugin]=false&flashvars[topBarContainer.plugin]=false&flashvars[unMuteOverlayButton.plugin]=false&flashvars[autoPlay]=true&flashvars[loop]=true&flashvars[chapters.thumbnailRotator]=false&flashvars[streamSelector.plugin]=true&flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&flashvars[dualScreen.plugin]=true&flashvars[Kaltura.addCrossoriginToIframe]=true&&wid=1_jviqc6x5',
  scenarioBstep3:
    'https://cdnapisec.kaltura.com/p/1511271/sp/151127100/embedIframeJs/uiconf_id/44723621/partner_id/1511271?iframeembed=true&playerId=kaltura_player&entry_id=1_3ife0bo4&flashvars[akamaiHD.loadingPolicy]=preInitialize&flashvars[akamaiHD.asyncInit]=true&flashvars[twoPhaseManifest]=true&flashvars[streamerType]=hdnetworkmanifest&flashvars[localizationCode]=en&flashvars[leadWithHTML5]=true&flashvars[sideBarContainer.plugin]=true&flashvars[sideBarContainer.position]=left&flashvars[sideBarContainer.clickToClose]=true&flashvars[chapters.plugin]=true&flashvars[chapters.layout]=vertical&flashvars[chapters.thumbnailRotator]=false&flashvars[sideBarContainer.plugin]=false&flashvars[controlBarContainer.plugin]=false&flashvars[topBarContainer.plugin]=false&flashvars[unMuteOverlayButton.plugin]=false&flashvars[autoPlay]=true&flashvars[loop]=true&flashvars[streamSelector.plugin]=true&flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&flashvars[dualScreen.plugin]=true&flashvars[Kaltura.addCrossoriginToIframe]=true&&wid=1_benutkl7',
  scenarioBstep4:
    'https://cdnapisec.kaltura.com/p/1511271/sp/151127100/embedIframeJs/uiconf_id/44723621/partner_id/1511271?iframeembed=true&playerId=kaltura_player&entry_id=1_5y9l144n&flashvars[akamaiHD.loadingPolicy]=preInitialize&flashvars[akamaiHD.asyncInit]=true&flashvars[twoPhaseManifest]=true&flashvars[streamerType]=hdnetworkmanifest&flashvars[localizationCode]=en&flashvars[leadWithHTML5]=true&flashvars[sideBarContainer.plugin]=true&flashvars[sideBarContainer.position]=left&flashvars[sideBarContainer.clickToClose]=true&flashvars[chapters.plugin]=true&flashvars[chapters.layout]=vertical&flashvars[sideBarContainer.plugin]=false&flashvars[controlBarContainer.plugin]=false&flashvars[topBarContainer.plugin]=false&flashvars[unMuteOverlayButton.plugin]=false&flashvars[autoPlay]=true&flashvars[loop]=true&flashvars[chapters.thumbnailRotator]=false&flashvars[streamSelector.plugin]=true&flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&flashvars[dualScreen.plugin]=true&flashvars[Kaltura.addCrossoriginToIframe]=true&&wid=1_777cg1qz',
};

export default {
  ...images,
  ...videos,
};
