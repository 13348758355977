module.exports = {
  entry: './src/apps/reaqta-interactive-tour/index.tsx',
  shortcode: 'reaqta-interactive-tour',
  title: 'ReaQta Interactive Tour',
  status: 'stable',
  description:
    'This interactive walkthrough demo will show how you can remediate threats quickly with ReaQta.',
  preview: {
    url: `${process.env.PUBLIC_URL}/storybook/iframe.html?id=widgets-reaqta-interactive-tour-components--default&viewMode=story`,
  },
  useExternalPeerDependencies: ['react', 'react-dom', 'react-intl'],
  additionalCustomProperties: {
    webSegmentPaths: ['/resources', 'product', 'security'],
    availableTranslations: [
      'de',
      'en',
      'es',
      'esla',
      'fr',
      'it',
      'ja',
      'ko',
      'pt',
      'zh-cn',
    ],
  },
};
